import React, { ReactElement, useEffect, useMemo, useState } from "react";
import Image, { StaticImageData } from "next/image";

import { useWebTranslation } from "../../translations";
import { i18n } from "next-i18next";

import Container from "../../components-shared/Container";
import ContentfulMedia from "../../components-shared/ContentfulMedia";
import RichText from "../../components-shared/RichText";
import ArticleCard from "../../components-shared/ArticleCard";
import ArticleStructuredData from "../StructuredData";
import ArticleHead from "./ArticleHead";
import { LayoutPage } from "../PageTemplates/Layout";
import { ArticleCardArticle } from "../../../__generated__/ArticleCardArticle";
import { IArticleProps } from "../../interfaces/components/Articles";
import { ContentfulHeaderProps } from "../../contentful/getContentfulHeader";

import { Typography } from "../../ui-kit/Typography";
import Gutter from "../../ui-kit/Gutter";

import AppExampleSWE from "../../assets/images/app-phone-example-swe.webp";
import AppExampleGER from "../../assets/images/app-phone-example-ger.png";

import styles from "./Article.module.css";
import AppStoreIcons from "components/ContentfulRenderer/ContentfulComponent/AppStoreIcons/AppStoreIcons";
import useBreakPoints from "hooks/useMediaQuery";
import SmartAppBanner from "components/SmartAppBanner/SmartAppBanner";
import { Locale } from "config/site";

const images: Record<Locale, StaticImageData> = {
  en: AppExampleSWE, // TODO: Update to eng asset
  de: AppExampleGER,
  sv: AppExampleSWE,
  fr: AppExampleSWE,
};

const Article = ({
  article,
  latestArticles,
  navigationItems,
  siteLanguages,
  seo,
}: IArticleProps & ContentfulHeaderProps): ReactElement => {
  const { t } = useWebTranslation();
  const { isMobile } = useBreakPoints();

  const latestArticlesView = useMemo(
    () => (
      <>
        <Typography variant="h3">{t("article.latest")}</Typography>
        <Gutter size={3} />
        <div className={styles.latestArticles}>
          <Container>
            {latestArticles.slice(0, 3).map((item) => (
              <React.Fragment key={item?.sys.id}>
                <ArticleCard
                  variant="white"
                  article={item as ArticleCardArticle}
                />
              </React.Fragment>
            ))}
          </Container>
        </div>
      </>
    ),
    [latestArticles]
  );

  return (
    <LayoutPage
      navigationItems={navigationItems}
      siteLanguages={siteLanguages}
      title={article?.metadataTitle || article?.title || "Lassie"}
      description={article?.seoDescription || article?.shortText || ""}
      seo={seo}
    >
      {isMobile && <SmartAppBanner />}
      <ArticleHead article={article} />
      <div className={styles.container}>
        <main className={styles.main}>
          <article className={styles.article}>
            {article && (
              <>
                {article?.mainImage && (
                  <ContentfulMedia
                    media={article?.mainImage}
                    display="header"
                  />
                )}
                <section className={styles.articleText}>
                  <Typography variant="h1">{article.title}</Typography>
                  <Gutter size={2} />
                  <RichText
                    json={article.content?.json}
                    assets={article.content?.links.assets.block ?? []}
                    hyperlinks={article?.content?.links.entries.hyperlink}
                  />
                </section>
              </>
            )}

            <section>
              <div className={styles.downloadApp}>
                <Image
                  src={images[(i18n?.language ?? "en") as Locale]}
                  alt={t("navigation.our_app")}
                  width={400}
                  style={{ height: "auto" }}
                  sizes="(min-width: 500px) 200px, (min-width: 768px) 400px"
                />

                <div className={styles.downloadAppText}>
                  <Typography variant="h3">
                    {t("article.download_app.title")}
                  </Typography>
                  <Typography variant="body2">
                    {t("article.download_app.body")}
                  </Typography>
                </div>
              </div>

              <div className={styles.appStoreIcons}>
                <AppStoreIcons />
              </div>
            </section>

            <ArticleStructuredData
              headline={article?.title || ""}
              description={article?.shortText || ""}
              image={article?.mainImage?.url || null}
              datePublished={
                article?.sys?.publishedAt
                  ? new Date(article?.sys?.publishedAt).toISOString()
                  : ""
              }
              author={article?.author?.name || null}
            />
            <Gutter size={8} />
          </article>
          <Gutter size={8} />

          <section className={styles.latestArticlesContainer}>
            {latestArticlesView}
          </section>
          <Gutter size={12} />
        </main>
      </div>
    </LayoutPage>
  );
};

export default Article;

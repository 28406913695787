import React, { CSSProperties, ElementType, FC, ReactNode } from "react";
import styles from "./typography.module.css";
import classNames from "classnames";
import { TypographyVariant } from "./types";
import Colors from "../colors";

interface TypographyPropsTypes {
  variant?: keyof typeof TypographyVariant;
  align?: "center" | "left" | "right";
  children?: ReactNode;
  className?: string;
  color?: keyof typeof Colors;
  component?: ElementType;
  style?: CSSProperties;
  testId?: string;
  ref?: React.RefObject<HTMLElement>;
  truncate?: boolean;
}

const getComponent = (variant: TypographyVariant) => {
  if (variant === TypographyVariant.display) {
    return "h1";
  }

  if (
    [
      TypographyVariant.h1,
      TypographyVariant.h2,
      TypographyVariant.h3,
      TypographyVariant.h4,
      TypographyVariant.h5,
      TypographyVariant.h6,
    ].includes(variant)
  ) {
    return variant as ElementType;
  }

  return "span";
};

const Typography: FC<TypographyPropsTypes> = ({
  variant = "body2",
  align,
  color,
  children,
  className,
  component,
  style,
  testId,
  ref,
  truncate,
}) => {
  const Component = component ?? getComponent(TypographyVariant[variant]);

  return (
    <Component
      ref={ref}
      className={classNames(
        styles[variant],
        className,
        truncate && styles.truncate
      )}
      style={{
        color: color && Colors[color],
        textAlign: align,
        ...style,
      }}
      data-testid={testId}
    >
      {children}
    </Component>
  );
};

export default Typography;

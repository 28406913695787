import { captureException } from "@sentry/nextjs";
import { GetStaticPaths, GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { FC, useEffect } from "react";
import {
  ArticleData,
  ArticleDataVariables,
  ArticleData_articleCollection_items,
} from "../../__generated__/ArticleData";
import {
  LatestArticlesData,
  LatestArticlesDataVariables,
  LatestArticlesData_topicCollection_items_linkedFrom_articleCollection_items,
} from "../../__generated__/LatestArticlesData";
import { Page, PageVariables } from "../../__generated__/Page";
import {
  PublishedPages,
  PublishedPagesVariables,
  PublishedPages_pageCollection_items,
} from "../../__generated__/PublishedPages";
import { Site, SiteVariables } from "../../__generated__/Site";
import client from "../../apollo-client";
import Article from "../components/Article/Article";
import { ContentfulArticleList } from "../components/ContentfulRenderer/ContentfulArticleList/ArticleList";
import { ContentfulLayoutRenderer } from "../components/ContentfulRenderer";
import { ComponentTypeNames } from "../components/ContentfulRenderer/type-name-mappings";
import { LayoutPage } from "../components/PageTemplates/Layout";
import { getContentfulFaqItems } from "../contentful/getContentfulFaqItems";
import { getContentfulHeaderProps } from "../contentful/getContentfulHeader";
import {
  ARTICLE_DATA,
  GET_ARTICLE_PAGE_SLUGS_BY_ID,
  GET_PAGE,
  GET_PAGE_ID,
  GET_PAGE_SLUGS_BY_SLUG,
  GET_PUBLISHED_PAGES,
  GET_SITE,
  LATEST_ARTICLES_DATA,
} from "../contentful/queries";

import { logEvent } from "../analytics";
import { namespaces } from "../translations";
import { getDomain, getLocales } from "../utils/site";
import { contentfulLocale, isDefined, websiteLocale } from "../utils/utils";
import { PageId, PageIdVariables } from "../../__generated__/PageId";
import {
  GetArticlePageSlugsById,
  GetArticlePageSlugsByIdVariables,
} from "../../__generated__/GetArticlePageSlugsById";
import {
  GetPageSlugsBySlug,
  GetPageSlugsBySlugVariables,
} from "../../__generated__/GetPageSlugsBySlug";
import useIntercom from "hooks/useIntercom";

enum PageType {
  Article = "Article",
  Page = "Page",
}

const SlugPage: FC<any> = ({
  components,
  page,
  pageType,
  article,
  relatedArticles,
  navigationItems,
  siteLanguages,
  seo,
}) => {
  useEffect(() => {
    if (page?.slug) {
      logEvent("USER_AT_PAGE", { slug: page.slug as string });
    }
  }, [page]);

  useIntercom();

  if (pageType === PageType.Article && article) {
    return (
      <Article
        article={article}
        latestArticles={relatedArticles ?? []}
        navigationItems={navigationItems}
        siteLanguages={siteLanguages}
        seo={seo}
      />
    );
  }

  const {
    title,
    seoTitle,
    seoDescription,
    seoKeywords,
    seoNoIndex,
    seoNoFollow,
    banner,
  } = page || {};

  return (
    <LayoutPage
      navigationItems={navigationItems}
      siteLanguages={siteLanguages}
      background="white"
      title={title as string}
      description={seoDescription as string}
      seoNoFollow={seoNoFollow as boolean}
      seoKeywords={seoKeywords as string[]}
      seoTitle={seoTitle as string}
      seoNoIndex={seoNoIndex as boolean}
      seo={seo}
    >
      <ContentfulLayoutRenderer banner={banner} components={components} />
    </LayoutPage>
  );
};

async function getPagePaths(
  pages: (PublishedPages_pageCollection_items | null)[],
  locale: string
) {
  return pages
    ?.filter((page) => !!page?.slug)
    .map((page) => {
      const slug = page!.slug!.split("/");
      return {
        params: { slug: slug[0]?.length > 0 ? slug : undefined },
        locale: websiteLocale(locale),
      };
    });
}

export const getStaticPaths: GetStaticPaths = async () => {
  const locales = getLocales();

  const pathPromises = locales.map(async (locale) => {
    const { data: siteForDomain } = await client.query<Site, SiteVariables>({
      query: GET_SITE,
      variables: {
        domain: getDomain(),
      },
    });

    try {
      const { data: publishedPages, error } = await client.query<
        PublishedPages,
        PublishedPagesVariables
      >({
        query: GET_PUBLISHED_PAGES,
        errorPolicy: "all",
        variables: {
          locale: contentfulLocale(locale),
        },
      });

      if (error) {
        captureException(error);
      }

      const pages =
        publishedPages.pageCollection?.items.filter((page) => {
          return (
            page?.linkedFrom?.siteCollection?.items[0]?.sys.id ===
            siteForDomain.siteCollection?.items[0]?.sys.id
          );
        }) ?? [];
      const pagePaths = await getPagePaths(pages, locale);

      return pagePaths;
    } catch (err) {
      captureException(err);
      return [];
    }
  });

  const paths = (await Promise.all<any>(pathPromises)).flatMap((e) => e);
  return { paths, fallback: "blocking" };
};

async function getArticlePageProps(slug: string, locale: string) {
  const { data: articleData } = await client.query<
    ArticleData,
    ArticleDataVariables
  >({
    query: ARTICLE_DATA,
    variables: {
      where: { uri: "/" + slug },
      locale: contentfulLocale(locale),
    },
  });

  return articleData.articleCollection?.items[0];
}

async function getRelatedArticlesPageProps(
  article: ArticleData_articleCollection_items,
  locale: string
) {
  const { data: latestArticlesData } = await client.query<
    LatestArticlesData,
    LatestArticlesDataVariables
  >({
    query: LATEST_ARTICLES_DATA,
    variables: {
      locale: contentfulLocale(locale),
      where: {
        sys: {
          id_in:
            article?.topicsCollection?.items
              .filter(isDefined)
              .map((item) => item?.sys.id) ?? [],
        },
      },
    },
  });

  return latestArticlesData.topicCollection?.items
    .reduce(
      (result, topic) => [
        ...result,
        ...(topic?.linkedFrom?.articleCollection?.items || []),
      ],
      [] as (LatestArticlesData_topicCollection_items_linkedFrom_articleCollection_items | null)[]
    )
    .filter(
      (item, index, self) =>
        item?.uri !== article?.uri &&
        self.findIndex((value) => value?.sys.id === item?.sys.id) === index &&
        !item?.video
    );
}

async function getEnglishSlug({ id }: { id: string }) {
  const { data, error } = await client.query<Page, PageVariables>({
    query: GET_PAGE,
    errorPolicy: "all",
    variables: {
      preview: false,
      locale: "en",
      id,
    },
  });

  return data.page?.slug;
}

export const getStaticProps: GetStaticProps = async (context) => {
  // @ts-ignore - always an array
  const slug = context.params?.slug?.join("/") ?? "/";

  const locale = context.locale!;
  const headerProps = await getContentfulHeaderProps(locale);
  const { data: pagesForSlugData } = await client.query<
    PageId,
    PageIdVariables
  >({
    query: GET_PAGE_ID,
    variables: {
      slug,
      locale: contentfulLocale(locale),
    },
  });

  const pageForSlug = pagesForSlugData.pageCollection?.items.find((page) =>
    page?.linkedFrom?.siteCollection?.items.some(
      (site) => site?.domain === getDomain()
    )
  );

  const pageIdForSlug = pageForSlug?.sys.id;

  if (pageIdForSlug) {
    const slugInEnglish =
      locale === "en" ? slug : await getEnglishSlug({ id: pageIdForSlug });

    const { data: hrefLang } = await client.query<
      GetPageSlugsBySlug,
      GetPageSlugsBySlugVariables
    >({
      query: GET_PAGE_SLUGS_BY_SLUG,
      errorPolicy: "all",
      variables: {
        slug: slugInEnglish,
      },
    });

    const { data, error } = await client.query<Page, PageVariables>({
      query: GET_PAGE,
      errorPolicy: "all",
      variables: {
        preview: false,
        locale: contentfulLocale(locale),
        id: pageIdForSlug,
      },
    });

    if (error) {
      captureException(error);
    }

    const page = data.page;

    const components = !!page?.bodyCollection?.items.length
      ? page?.bodyCollection?.items
      : page?.content?.componentsCollection?.items; //Deprecated

    if (components) {
      const decoratedComponents = await Promise.all(
        [...components]
          .filter((component) => !!component?.sys?.publishedAt)
          .map(async (component) => {
            const isArticleList =
              component?.__typename === ComponentTypeNames.ArticleList;

            if (isArticleList) {
              //We need to get articles for ArticleList in dedicated query to prevent query complexity from exceeding limit
              const articleListComponent = await client.query({
                query: ContentfulArticleList.fragmentFull,
                variables: {
                  preview: false,
                  locale: contentfulLocale(locale ?? ""),
                  id: component?.sys.id,
                },
              });

              return articleListComponent.data.componentArticleList;
            }

            const isFaqBlock =
              component?.__typename === ComponentTypeNames.FaqBlock;

            if (isFaqBlock && component.tagId) {
              const tagItems = await getContentfulFaqItems(
                component.tagId,
                locale ?? ""
              );
              return { ...component, tagItems };
            }

            return component;
          })
      );

      return {
        props: {
          components: decoratedComponents,
          seo: {
            hrefLang: hrefLang?.pageSlugsBySlug ?? null,
          },
          page,
          pageType: PageType.Page,
          ...(await serverSideTranslations(
            locale as string,
            namespaces as string[]
          )),
          article: null,
          relatedArticles: null,
          ...headerProps,
        },
        revalidate: 3600,
      };
    } else {
      console.warn(
        "Possible: Contentful component Fragment has no sys.publishedAt property, skipping it."
      );
    }
  }

  const article = await getArticlePageProps(slug, locale);

  if (article) {
    const { data: hrefLang } = await client.query<
      GetArticlePageSlugsById,
      GetArticlePageSlugsByIdVariables
    >({
      query: GET_ARTICLE_PAGE_SLUGS_BY_ID,
      errorPolicy: "all",
      variables: {
        id: article.sys.id,
      },
    });

    const relatedArticles = await getRelatedArticlesPageProps(article, locale);

    return {
      props: {
        page: null,
        seo: {
          hrefLang: hrefLang?.articlePageSlugsById ?? null,
        },
        pageType: PageType.Article,
        article,
        relatedArticles,
        ...(await serverSideTranslations(
          locale as string,
          namespaces as string[]
        )),
        ...headerProps,
      },
      revalidate: 3600,
    };
  }

  console.log(
    `Slug page error. Did not detect page from url. Slug ${slug}, locale: ${locale}`
  );

  return {
    notFound: true,
  };
};

export default SlugPage;

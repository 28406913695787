import {
  useCombobox,
  UseComboboxPropGetters,
  UseComboboxSelectedItemChange,
  useSelect,
  UseSelectPropGetters,
  UseSelectSelectedItemChange,
} from "downshift";
import type { ReactNode } from "react";
import styles from "./SelectBox.module.css";
import { Icon } from "@/ui-kit";
import Colors from "ui-kit/colors";
import classnames from "classnames";

function List<T>({
  getMenuProps,
  getItemProps,
  isOpen,
  highlightedIndex,
  items,
  itemToString,
}: {
  getMenuProps:
    | UseSelectPropGetters<T>["getMenuProps"]
    | UseComboboxPropGetters<T>["getMenuProps"];
  getItemProps:
    | UseSelectPropGetters<T>["getItemProps"]
    | UseComboboxPropGetters<T>["getItemProps"];
  isOpen: boolean;
  highlightedIndex: number;
  items: T[];
  itemToString?: (item: T | null) => string;
}) {
  return (
    <div className={styles.listWrapper}>
      <ul {...getMenuProps()} className={styles.listContainer}>
        {isOpen && items.length > 0 && (
          <div className={styles.list}>
            {items.map((item, index) => (
              <li
                className={styles.listItem}
                style={{
                  backgroundColor:
                    highlightedIndex === index ? Colors.grey200 : undefined,
                }}
                key={`${item}${index}`}
                {...getItemProps({
                  item,
                  index,
                })}
              >
                {itemToString && itemToString(item)}
              </li>
            ))}
          </div>
        )}
      </ul>
    </div>
  );
}

export function Select<T>({
  onSelectedItemChange,
  items = [],
  itemToString,
  defaultSelectedItem,
  placeholder,
  icon,
}: {
  onSelectedItemChange: (
    changes: UseSelectSelectedItemChange<T | null>
  ) => void;
  items: T[];
  itemToString?: (item: T | null) => string;
  defaultSelectedItem?: T | null;
  placeholder?: string;
  icon?: ReactNode;
}) {
  const {
    isOpen,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useSelect({
    items,
    itemToString,
    onSelectedItemChange,
    defaultSelectedItem,
  });

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer} {...getToggleButtonProps()}>
        {icon ? <div style={{ display: "flex" }}>{icon}</div> : null}
        <input
          className={styles.input}
          placeholder={placeholder}
          value={itemToString && itemToString(selectedItem)}
          style={{ cursor: "pointer" }}
          readOnly
        />
        <button
          className={classnames(styles.button, styles.toggleButton)}
          aria-label="toggle menu"
          data-testid="combobox-toggle-button"
        >
          {
            <Icon
              name={isOpen ? "chevron-up" : "chevron-down"}
              size="S"
              color={Colors.grey300}
            />
          }
        </button>
      </div>
      <List
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
        isOpen={isOpen}
        highlightedIndex={highlightedIndex}
        items={items}
        itemToString={itemToString}
      />
    </div>
  );
}

export function SelectBox<T>({
  onInputValueChange,
  onSelectedItemChange,
  items = [],
  itemToString,
  defaultSelectedItem,
  placeholder,
  showListToggleButton,
  icon,
  allowClear,
}: {
  onInputValueChange: ({ inputValue }: { inputValue: string }) => void;
  onSelectedItemChange: (
    changes: UseComboboxSelectedItemChange<T | null>
  ) => void;
  items: T[];
  itemToString?: (item: T | null) => string;
  defaultSelectedItem?: T | null;
  placeholder?: string;
  showListToggleButton?: boolean;
  icon?: ReactNode;
  allowClear?: boolean;
}) {
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getToggleButtonProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    selectItem,
  } = useCombobox({
    items,
    onInputValueChange,
    onSelectedItemChange,
    itemToString,
    defaultSelectedItem,
  });

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        {icon ? <div style={{ display: "flex" }}>{icon}</div> : null}
        <input
          className={styles.input}
          {...getInputProps()}
          placeholder={placeholder}
        />
        {allowClear && selectedItem ? (
          <button
            className={classnames(styles.button, styles.clearButton)}
            aria-label="clear menu"
            data-testid="clear-button"
            onClick={() => selectItem(null)}
          >
            <Icon name="error-circle" size="S" color={Colors.grey300} />
          </button>
        ) : null}
        {showListToggleButton ? (
          <button
            className={classnames(styles.button, styles.toggleButton)}
            aria-label="toggle menu"
            data-testid="combobox-toggle-button"
            {...getToggleButtonProps()}
          >
            {
              <Icon
                name={isOpen ? "chevron-up" : "chevron-down"}
                size="S"
                color={Colors.grey300}
              />
            }
          </button>
        ) : null}
      </div>
      <List
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
        isOpen={isOpen}
        highlightedIndex={highlightedIndex}
        items={items}
        itemToString={itemToString}
      />
    </div>
  );
}
